import { eqJson } from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import { assign, cloneDeep, get, has, hasIn, unset } from "lodash";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FiltersMixin<
  T extends Record<string, any> = Record<string, any>
> extends Vue {
  iKeyReactive = 1;

  get modelFilters(): T {
    return get(cloneDeep(this.filters), this.modelClassKey, {}) as T;
  }

  get filters(): Record<string, T> {
    return AppModule.filters as Record<string, T>;
  }

  get modelClassKey(): string {
    if (hasIn(this, "sModelFilterKey")) {
      return get(this, "sModelFilterKey") as string;
    }

    if (hasIn(this, "modelClassName")) {
      return get(this, "modelClassName") as string;
    }

    return "global";
  }

  // @Watch("filters", { deep: true })
  onSetFilters(obFilters: T, merge = false) {
    if (
      !this.modelClassKey ||
      (eqJson(this.modelFilters, obFilters) && !merge)
    ) {
      return;
    }

    if (merge) {
      assign(obFilters, this.modelFilters);
    }

    AppModule.addFilter({
      model: this.modelClassKey,
      value: cloneDeep(obFilters) as Record<string, any>,
    });
  }

  onForgetFilter(sKey: string) {
    const sModelClass = this.modelClassKey;
    const obModelFilters = get(this.filters, sModelClass);
    if (!has(obModelFilters, sKey)) {
      return;
    }

    unset(obModelFilters, sKey);
    this.onSetFilters(obModelFilters);
  }
}
