<template>
  <module-container
    route-name="paymentmethods"
    show-toolbar
    :title="$t('paymentmethods')"
  >
    <template #route>
      <router-view />
    </template>

    <template #filters>
      <payment-method-filters />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaymentMethodFilters from "@/modules/paymentmethods/components/PaymentMethodFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { PaymentMethodFilters },
})
export default class PaymentmethodsIndex extends Mixins(
  PaginateProviderMixin
) {}
</script>
