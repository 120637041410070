<template>
  <sheet-filters
    v-model="obFilterData"
    @apply="applyFilters"
    @reset="resetFilters"
    :persistent="persistent"
    model-name="PaymentMethod"
  >
    <template #default="{ apply }">
      <v-row>
        <v-col cols="4" lg="2" v-if="!hideActive">
          <v-switch
            :label="$t('active')"
            :value="1"
            v-model="obFilterData.active"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('paymentmethod.in_account')"
            :value="1"
            v-model="obFilterData.in_account"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('paymentmethod.in_invoice')"
            :value="1"
            v-model="obFilterData.in_invoice"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col cols="4" lg="2">
          <v-switch
            :label="$t('configurable')"
            :value="1"
            v-model="obFilterData.configurable"
            @change="apply"
          ></v-switch>
        </v-col>

        <v-col v-bind="colsSearchField">
          <search-field v-model="obFilterData.search" @save="apply" clearable />
        </v-col>
      </v-row>
      <loading-overlay :loading="loading" local />
    </template>
  </sheet-filters>
</template>

<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import SheetFilters from "@/components/form/SheetFilters.vue";
import SearchField from "@/components/form/fields/Search.vue";
import LoadingOverlay from "@/components/common/loading/index.vue";

@Component({
  components: { SheetFilters, SearchField, LoadingOverlay },
})
export default class PaymentMethodFilters extends Vue {
  obFilterData: Record<string, any> = {};
  @Prop(Boolean) readonly persistent!: boolean;
  @Prop(Boolean) readonly hideActive!: boolean;
  @Prop(Boolean) readonly loading!: boolean;
  @Prop(Boolean) readonly preventApplyOnReset!: boolean;

  applyFilters() {
    this.$emit("apply", this.obFilterData);
  }

  resetFilters() {
    this.$emit("input", {});
    this.$emit("reset");
    if (this.preventApplyOnReset) {
      return;
    }

    this.applyFilters();
  }

  get colsSearchField() {
    return {
      cols: this.hideActive ? 12 : 8,
      lg: this.hideActive ? 6 : 5,
      xl: this.hideActive ? 6 : 4,
    };
  }
}
</script>
