<template>
  <form-field-text
    v-model="name"
    :label="label"
    :input-type="type"
    v-bind="$attrs"
    @input:debounce="save"
    @change:debounce="save"
    @save="save"
  >
    <template #append>
      <icon-search-outline size="1.5rem" outlined />
    </template>
  </form-field-text>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import FormFieldText from "@/components/form/fields/Text.vue";

@Component({
  components: { FormFieldText },
})
export default class FormFieldSearch extends Vue {
  @VModel({ type: [String, Number], default: "" }) name!: string;
  @Prop({ type: String, default: "search" }) readonly label!: string;
  @Prop({ type: String, default: "text" }) readonly type!: string;

  save(sValue: string) {
    this.$emit("save", sValue);
  }
}
</script>
